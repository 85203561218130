import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import waveImage from '../../assets/images/wave2.svg';
import cancelImage from '../../assets/images/cancel2.svg';

const CancellationSuccessScreen = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#4C3699',
        backgroundImage: `url(${waveImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: {
          xs: 'center 120%',
          sm: 'center 130%',
          md: 'center 140%',
        },
        padding: { xs: '0.5em', sm: '0.75em', md: '1.25em' },
        textAlign: 'center',
      }}
    >
      <Box
        component="img"
        src={cancelImage}
        alt={t('cancelConfirmationIllustration')}
        sx={{
          maxWidth: { xs: '11.25em', sm: '13.75em', md: '18.75em' },
          height: 'auto',
          mb: '1em',
        }}
      />

      <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#fff', mb: '0.5em' }}>
        {t('cancellationSuccess')}
      </Typography>
      <Typography variant="body1" sx={{ color: '#D1C4E9', maxWidth: '25em' }}>
        {t('pauseAndReturn')}
      </Typography>
    </Box>
  );
};

export default CancellationSuccessScreen;
