import React, { useState } from 'react';
import { Box, Typography, Paper, List, ListItem, ListItemText, ListItemSecondaryAction, Divider, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import waveImage from '../../assets/images/wave2.svg';
import plansImage from '../../assets/images/plans.svg';

const PlansScreen = () => {
  const { t } = useTranslation();
  const [openDropdown, setOpenDropdown] = useState<null | number>(null);

  const handleToggleDropdown = (index: number) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#4C3699',
        backgroundImage: `url(${waveImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: {
          xs: 'center 120%',
          sm: 'center 130%',
          md: 'center 140%',
        },
        padding: { xs: '0.5em', sm: '0.75em', md: '1.25em' },
        position: 'relative',
      }}
    >
      <Box
        component="img"
        src={plansImage}
        alt="Plans illustration"
        sx={{
          display: { xs: 'none', md: 'block' },
          position: 'absolute',
          left: { md: '8%', lg: '14%' },
          bottom: { md: '10%', lg: '16%' },
          maxWidth: { md: '15.625em', lg: '21.875em' },
          height: 'auto',
          zIndex: 1,
        }}
      />

      <Paper
        sx={{
          width: '100%',
          maxWidth: '35em',
          padding: { xs: '1em', sm: '1.5em', md: '2.5em' },
          borderRadius: '1em',
          backgroundColor: '#fff',
          color: '#3A2771',
          boxShadow: '0em 0.75em 1.5em rgba(0, 0, 0, 0.3)',
          zIndex: 2,
          marginLeft: { md: '13%', lg: '18%' },
        }}
        elevation={4}
      >
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Box
              sx={{
                width: '2.5em',
                height: '2.5em',
                backgroundColor: '#4C3699',
                borderRadius: '0.5em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h6" sx={{ color: '#92EF8F', fontWeight: 'bold' }}>P</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#4C3699' }}>
              {t('changePlans')}
            </Typography>
          </Grid>
        </Grid>

        <Typography variant="body2" sx={{ color: '#b3b3b3', mt: '0.5em' }}>
          {t('planDescription')}
        </Typography>

        <Divider sx={{ backgroundColor: '#92EF8F', height: '0.125em', my: '1em' }} />

        <List sx={{ backgroundColor: '#fff', borderRadius: '1em', color: '#3A2771' }}>
          {['Basic Tier', 'Premium Tier', 'Gold Tier', 'On Demand'].map((plan, index) => (
            <React.Fragment key={index}>
              <ListItem button onClick={() => handleToggleDropdown(index)}>
                <ListItemText primary={t(plan)} />
                <ListItemSecondaryAction>
                  <ArrowForwardIosIcon sx={{ color: '#3A2771', fontSize: '1.125em' }} />
                </ListItemSecondaryAction>
              </ListItem>

              {openDropdown === index && (
                <Box
                  sx={{
                    backgroundColor: '#f9f9f9',
                    color: '#3A2771',
                    padding: '1em',
                    borderRadius: '1em',
                    boxShadow: '0em 0.25em 0.5em rgba(0, 0, 0, 0.1)',
                    margin: '0 1em',
                    marginTop: '0.25em',
                    overflow: 'hidden',
                    maxHeight: '12.5em',
                  }}
                >
                  <Typography variant="body2" sx={{ mb: '0.5em' }}>
                    {t(`Details${plan.replace(" ", "")}`)}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: '#4C3699',
                        color: '#fff',
                        '&:hover': { backgroundColor: '#3A2771' },
                      }}
                    >
                      {t('choose')}
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => setOpenDropdown(null)}
                      sx={{
                        color: '#4C3699',
                      }}
                    >
                      {t('close')}
                    </Button>
                  </Box>
                </Box>
              )}

              {index < 3 && (
                <Divider variant="fullWidth" component="li" sx={{ marginLeft: '0.5em', marginRight: '0.5em' }} />
              )}
            </React.Fragment>
          ))}
        </List>
      </Paper>
    </Box>
  );
};

export default PlansScreen;
