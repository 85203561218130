import React from "react";
import { Box, Typography, IconButton, Avatar, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MessagesTypes } from "../../../data/messages";
import { formateDate } from "../../../utils";
import RepliedMessage from "./RepliedMessage";

interface MessageProps {
  message: MessagesTypes;
  chatUserDetails: any;
  onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | MessagesTypes | undefined) => void;
  isFromMe: boolean;
  onOpenForward: (message: MessagesTypes) => void;
  isChannel: boolean;
  onDeleteImage: (messageId: string | number, imageId: string | number) => void;
}

const Message = ({
  message,
  chatUserDetails,
  onDelete,
  onSetReplyData,
  isFromMe,
  onOpenForward,
  isChannel,
  onDeleteImage,
}: MessageProps) => {
  const { t } = useTranslation();
  const date = formateDate(message.time, "hh:mmaaa");
  const hasText = message.text;
  const isRepliedMessage = message.replyOf;
  const isForwarded = message.meta.isForwarded;

  const onClickReply = () => onSetReplyData(message);

  return (
    <Box
      display="flex"
      justifyContent={isFromMe ? "flex-end" : "flex-start"}
      p={2}
    >
      <Avatar
        src={isFromMe ? chatUserDetails?.profileImage : undefined}
        alt={isFromMe ? "You" : chatUserDetails?.name}
        sx={{ mr: 2 }}
      />
      <Box
        bgcolor={isFromMe ? "primary.main" : "grey.200"}
        color={isFromMe ? "primary.contrastText" : "text.primary"}
        p={2}
        borderRadius={2}
        maxWidth="70%"
      >
        {isForwarded && (
          <Typography variant="caption" color="textSecondary" display="block">
            {t("Forwarded")}
          </Typography>
        )}

        {isRepliedMessage && (
          <RepliedMessage
            fullName={chatUserDetails.firstName}
            message={message}
            isFromMe={isFromMe}
          />
        )}

        {hasText && (
          <Typography variant="body1" gutterBottom>
            {message.text}
          </Typography>
        )}

        <Box display="flex" justifyContent="space-between">
          <Typography variant="caption" color="#a5a5a5">
            {date}
          </Typography>

          <Tooltip title={t("Reply")}>
            <IconButton size="small" onClick={onClickReply} sx={{color: "#a5a5a5"}}>
              <i className="bx bx-reply"></i>
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default Message;
