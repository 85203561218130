import React from 'react';
import { Box, Typography, Grid, Paper, List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PaymentIcon from '@mui/icons-material/Payment';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import waveImage from "../../assets/images/wave.svg";

const SubscriptionDetails = () => {
  const { t } = useTranslation();

  return (
    // Background and Container Box
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#4C3699',
        backgroundImage: `url(${waveImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: '0 -160%',
        padding: '1.25em',
        position: 'relative',
      }}
    >
      {/* Main Paper Container */}
      <Paper
        sx={{
          width: '100%',
          maxWidth: '40.625em',
          padding: '1.5em',
          borderRadius: '1em',
          backgroundColor: '#fff',
          color: '#fff',
          boxShadow: '0em 0.5em 1em rgba(0, 0, 0, 0.2)',
        }}
        elevation={4}
      >
        {/* Header with Icon and Titles */}
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <AccountCircleIcon sx={{ fontSize: '3.75em', color: '#4C3699' }} />
          </Grid>
          <Grid item>
            <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#4C3699' }}>
              {t('account')}
            </Typography>
            <Typography variant="body1" sx={{ color: '#b3b3b3' }}>
              {t('subscriptionDetails')}
            </Typography>
          </Grid>
        </Grid>

        {/* Subscription Details Box */}
        <Box
          sx={{
            position: 'relative',
            backgroundColor: '#fff',
            color: '#3A2771',
            borderRadius: '1em',
            padding: '0.75em',
            marginTop: '1em',
            boxShadow: '0em 0.25em 0.5em rgba(0, 0, 0, 0.1)',
          }}
        >
          {/* Subscription Badge */}
          <Box
            sx={{
              position: 'absolute',
              top: '-0.0625em',
              left: '-0.0625em',
              backgroundColor: '#00C853',
              borderRadius: '0.375em',
              padding: '0.25em 0.75em',
              fontSize: '0.875rem',
              fontWeight: 'bold',
              color: '#fff',
            }}
          >
            {t('subscriberSince', { date: 'August 2023' })}
          </Box>

          <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 2 }}>
            {t('basicPlan')}
          </Typography>
          <Typography variant="body2">
            {t('nextPayment', { date: 'November 3, 2024' })}
          </Typography>
          <Typography variant="body2" sx={{ mt: 1 }}>
            <span style={{ color: '#4C3699' }}>●●●● ●●●● ●●●●</span> 1234
          </Typography>
        </Box>

        
        <List sx={{ mt: '1em', backgroundColor: '#fff', borderRadius: '1em', color: '#3A2771', boxShadow: '0em 0.25em 0.5em rgba(0, 0, 0, 0.1)' }}>
          <ListItem component={Link} to="/manage-tiers" button>
            <ListItemIcon>
              <SwapHorizIcon sx={{ color: '#009688', fontSize: '1.75em' }} />
            </ListItemIcon>
            <ListItemText primary={t('changePlans')} />
            <ListItemSecondaryAction>
              <ArrowForwardIosIcon sx={{ color: '#3A2771', fontSize: '1.125em' }} />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" component="li" sx={{ marginLeft: '1em', marginRight: '1em' }} />

          <ListItem component={Link} to="/manage-payment" button>
            <ListItemIcon>
              <PaymentIcon sx={{ color: '#009688', fontSize: '1.75em' }} />
            </ListItemIcon>
            <ListItemText primary={t('payments')} />
            <ListItemSecondaryAction>
              <ArrowForwardIosIcon sx={{ color: '#3A2771', fontSize: '1.125em' }} />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" component="li" sx={{ marginLeft: '1em', marginRight: '1em' }} />

          <ListItem component={Link} to="/comprar-credito" button>
            <ListItemIcon>
              <AddShoppingCartIcon sx={{ color: '#009688', fontSize: '1.75em' }} />
            </ListItemIcon>
            <ListItemText primary={t('buyCredit')} />
            <ListItemSecondaryAction>
              <ArrowForwardIosIcon sx={{ color: '#3A2771', fontSize: '1.125em' }} />
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" component="li" sx={{ marginLeft: '1em', marginRight: '1em' }} />

          <ListItem component={Link} to="/cancel-subscription" button>
            <ListItemIcon>
              <CancelIcon sx={{ color: '#009688', fontSize: '1.75em' }} />
            </ListItemIcon>
            <ListItemText primary={t('cancelSubscription')} />
            <ListItemSecondaryAction>
              <ArrowForwardIosIcon sx={{ color: '#3A2771', fontSize: '1.125em' }} />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>
    </Box>
  );
};

export default SubscriptionDetails;
