import React, { useState } from "react";
import { Menu, MenuItem, IconButton, Tooltip } from "@mui/material";
import CustomModal from "../components/CustomModal";
import { useProfile } from "../hooks";
import { useTranslation } from "react-i18next";

import PersonIcon from "@mui/icons-material/Person";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PaymentIcon from "@mui/icons-material/Payment";
import BusinessIcon from "@mui/icons-material/Business";
import { Button, Box, Typography, Paper } from "@mui/material";

const ProfileDropdownMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const { userProfile } = useProfile();
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("profile")} placement="right" arrow>
        <IconButton
          onClick={handleClick}
          className="bg-transparent"
          sx={{ borderRadius: "16px" }}
        >
          <AccountCircleIcon fontSize="large" style={{ color: "#fff" }} />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ "& .MuiPaper-root": { borderRadius: "16px" } }}
      >
        <MenuItem
          className="d-flex align-items-center justify-content-between"
          onClick={() => {
            toggleModal();
            handleClose();
          }}
          sx={{ borderRadius: "12px" }}
        >
          {t("viewProfile")}{" "}
          <PersonIcon fontSize="small" className="text-muted ms-1" />
        </MenuItem>

        <MenuItem
          className="d-flex align-items-center justify-content-between"
          onClick={handleClose}
          component="a"
          href="/auth-create-new-password"
          sx={{ borderRadius: "12px" }}
        >
          {t("changePassword")}{" "}
          <LockOpenIcon fontSize="small" className="text-muted ms-1" />
        </MenuItem>

        {/*<MenuItem*/}
        {/*  className="d-flex align-items-center justify-content-between"*/}
        {/*  onClick={handleClose}*/}
        {/*  component="a"*/}
        {/*  href="/subscription"*/}
        {/*  sx={{ borderRadius: "12px" }}*/}
        {/*>*/}
        {/*  {t("manageSubscription")}{" "}*/}
        {/*  <PaymentIcon fontSize="small" className="text-muted ms-1" />*/}
        {/*</MenuItem>*/}

        <MenuItem
          className="d-flex align-items-center justify-content-between"
          onClick={handleClose}
          component="a"
          href="/logout"
          sx={{ borderRadius: "12px" }}
        >
          {t("logout")}{" "}
          <ExitToAppIcon fontSize="small" className="text-muted ms-1" />
        </MenuItem>
      </Menu>

      <CustomModal isOpen={modalOpen} toggle={toggleModal} title={t("userProfile")}>
        <Box sx={{ padding: 3, textAlign: "center", color: "#333" }}>
          <AccountCircleIcon sx={{ fontSize: 50, color: "#4a90e2" }} />

          <Typography variant="h6" sx={{ mt: 2, fontWeight: "bold" }}>
            {t("hello")} <strong>{userProfile?.userName || t("user")}</strong>
          </Typography>
          <Typography variant="body2" sx={{ color: "#666" }}>
            {t("currentlyLoggedIn")}
          </Typography>

          <Paper
            sx={{
              mt: 3,
              p: 2,
              borderRadius: 2,
              backgroundColor: "#f9f9f9",
              boxShadow: 1,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
              {t("accountDetails")}
            </Typography>
            <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
              <BusinessIcon sx={{ color: "#4a90e2" }} />
              <Typography variant="body1" component="strong">
                {userProfile?.companyName || t("company")}
              </Typography>
            </Box>
          </Paper>

          <Box mt={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleModal}
              sx={{
                fontWeight: "bold",
                borderRadius: 1,
              }}
            >
              {t("close")}
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
};

export default ProfileDropdownMenu;
