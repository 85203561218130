import React from 'react';
import {FaExternalLinkAlt} from "react-icons/fa";
import jessIcon from "../../../assets/images/jess.png";
import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Button
} from "reactstrap";
import {useTranslation} from "react-i18next";
import "../../../assets/scss/custom/pages/_status-page.scss";
import {Typewriter} from 'react-simple-typewriter';
import { STATUS } from "../../../constants";

interface StatusPageProps {
  jessPhoneNumber: string;
  statusJess: STATUS;
}

const JessPage: React.FC<StatusPageProps> = ({jessPhoneNumber, statusJess}) => {
  const {t} = useTranslation();

  const formatPhoneNumber = (phoneNumber: string): string => {
    const countryCode = "55";
    const numberWithoutCountryCode = phoneNumber.slice(2);
    const areaCode = numberWithoutCountryCode.slice(0, 2);
    const mainNumber = numberWithoutCountryCode.slice(2);

    const formattedNumber =
      mainNumber.length === 8
        ? `${mainNumber.slice(0, 4)}-${mainNumber.slice(4)}`
        : `${mainNumber.slice(0, 5)}-${mainNumber.slice(5)}`;

    return `+${countryCode} ${areaCode} ${formattedNumber}`;
  };

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs="12">
          <Card style={{ width: "100%", borderBottom: "1px solid rgb(222, 226, 230)", marginBottom: "2em", paddingBottom: "1em" }}>
            <CardBody className="d-flex align-items-center justify-content-between flex-column flex-lg-row">
              {/* Profile picture */}
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "100%",
                  border: statusJess === "Online" ? "solid 4px #06d6a0" : "solid 4px #dc3545",
                  marginRight: "20px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="d-flex justify-content-center align-items-center"
              >
                <img
                  src={jessIcon}
                  alt="WhatsApp Icon"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>

              {/* Text information */}
              <div className="d-flex flex-column text-center text-lg-start" style={{flex: 1}}>
                <p style={{margin: 0, fontSize: "25px"}}>Jess</p>
                <p style={{fontSize: "15px", color: "#6c757d", marginBottom: "5px"}}>
                  {statusJess === STATUS.Online && (
                    <span style={{color: "#06d6a0"}}>Online</span>
                  )}
                  {statusJess === STATUS.Offline && (
                    <span style={{color: "#dc3545"}}>Offline</span>
                  )}
                  {statusJess === STATUS.ConectWhatsapp && (
                    <span style={{color: "#e60000"}}>
                      {t("connect_whatsapp")}
                    </span>
                  )}
                  {statusJess === STATUS.OpenAgent && (
                    <span style={{color: "#e76f51"}}>{t("open_agent")}</span>
                  )}
                </p>
                {jessPhoneNumber ? (
                  <p
                    style={{
                      fontSize: "15px",
                      color: "#6c757d",
                      textDecoration: "none",
                      margin: 0,
                    }}
                  >
                    {formatPhoneNumber(jessPhoneNumber)}
                  </p>
                ) : (
                  <div style={{fontSize: "20px", color: "#6c757d"}}>
                    {t("message_phone_unavailable")}
                  </div>
                )}
              </div>

              {(statusJess === "Online") && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                  }}
                >
                  {/* Typing animated */}
                  <div
                    style={{
                      padding: "15px 20px",
                      textAlign: "center",
                      width: "20em"
                    }}
                  >
                    <Typewriter
                      words={[
                        t("how_can_i_help_you"),
                        t("customize_communication"),
                        t("tone_of_voice"),
                        t("language_style"),
                        t("essential_information"),
                        t("information_to_avoid"),
                        t("customized_communication_guide"),
                      ]}
                      loop={true}
                      cursor
                      cursorStyle="_"
                      typeSpeed={70}
                      deleteSpeed={10}
                      delaySpeed={3000}
                    />
                  </div>
                  {/* Action Button */}
                  <Button
                    style={{background: "#4e36a0"}}
                    target="_blank"
                    href={`https://wa.me/${jessPhoneNumber.replace(/\D/g, "")}`}
                  >
                    {t("give_instructions_to_jess")}
                    <FaExternalLinkAlt style={{marginLeft: "8px", fontSize: "14px"}}/>
                  </Button>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        {/*<div className="text-center my-5">*/}
        {/*  <img*/}
        {/*    src={emptyStateImage}*/}
        {/*    alt={t("No sessions yet")}*/}
        {/*    style={{ width: "150px", marginBottom: "20px" }}*/}
        {/*  />*/}
        {/*  <h5 className="subtitle">Error</h5>*/}
        {/*</div>*/}
      </Row>
    </Container>
  );
};

export default JessPage;
