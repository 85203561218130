import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Grid } from '@mui/material';
import girlPayment from "../../assets/images/girl-payment.svg";
import { useTranslation } from 'react-i18next';

const PaymentDetailsForm = () => {
  const { t } = useTranslation();
  const [cardNumber, setCardNumber] = useState('');
  const [expiryMonth, setExpiryMonth] = useState('');
  const [expiryYear, setExpiryYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [cardholderName, setCardholderName] = useState('');
  const [sessionId, setSessionId] = useState<string | null>(null);

  useEffect(() => {
    // Obtém o sessionId do backend para iniciar a sessão do PagSeguro
    fetch('http://localhost:5000/api/pagseguro-session')
      .then((response) => response.json())
      .then((data) => {
        if (data.sessionId) {
          setSessionId(data.sessionId);
          PagSeguroDirectPayment.setSessionId(data.sessionId);
        }
      })
      .catch(console.error);
  }, []);

  const handlePayment = async () => {
    if (!sessionId) {
      console.error('Session ID not set. Please try again.');
      return;
    }

    const senderHash = PagSeguroDirectPayment.getSenderHash();

    PagSeguroDirectPayment.createCardToken({
      cardNumber: cardNumber,
      brand: 'visa',
      cvv: cvv,
      expirationMonth: expiryMonth,
      expirationYear: expiryYear,
      success: function(response: any) {
        const cardToken = response.card.token;

        fetch('http://localhost:5000/api/payment', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            amount: 100.0,
            paymentMethod: 'creditCard',
            cardToken: cardToken,
            senderHash: senderHash,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.success) {
              console.log('Pagamento processado com sucesso:', data);
            } else {
              console.error('Erro no pagamento:', data.message);
            }
          })
          .catch((error) => console.error('Erro na requisição ao backend:', error));
      },
      error: function(response: any) {
        console.error('Erro ao gerar cardToken:', response);
      },
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        padding: { xs: 2, sm: 4 },
      }}
    >
      <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ maxWidth: { xs: '100%', md: '800px' }, padding: { xs: 2, md: 0 } }}>
        {/* Coluna da esquerda: Formulário */}
        <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', mb: { xs: 4, md: 0 } }}>
          <Typography variant="h5" gutterBottom>
            {t('confirmPayment')}
          </Typography>

          <TextField
            label={t('cardNumber')}
            variant="outlined"
            fullWidth
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            sx={{ mb: 2, borderRadius: '10px', '& fieldset': { borderRadius: '10px' } }}
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              label={t('expiryMonth')}
              variant="outlined"
              fullWidth
              value={expiryMonth}
              onChange={(e) => setExpiryMonth(e.target.value)}
              sx={{ borderRadius: '10px', '& fieldset': { borderRadius: '10px' } }}
            />
            <TextField
              label={t('expiryYear')}
              variant="outlined"
              fullWidth
              value={expiryYear}
              onChange={(e) => setExpiryYear(e.target.value)}
              sx={{ borderRadius: '10px', '& fieldset': { borderRadius: '10px' } }}
            />
            <TextField
              label={t('cvv')}
              variant="outlined"
              fullWidth
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
              sx={{ borderRadius: '10px', '& fieldset': { borderRadius: '10px' } }}
            />
          </Box>
          <TextField
            label={t('cardholderName')}
            variant="outlined"
            fullWidth
            value={cardholderName}
            onChange={(e) => setCardholderName(e.target.value)}
            sx={{ mt: 2, mb: 2, borderRadius: '10px', '& fieldset': { borderRadius: '10px' } }}
          />

          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 3, backgroundColor: '#562173', color: '#fff', fontWeight: 'bold' }}
            onClick={handlePayment}
          >
            {t('confirmPayment')}
          </Button>
        </Grid>

        {/* Coluna da direita: Ilustração */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: { xs: 'none', md: 'flex' },
            justifyContent: 'center',
            alignItems: 'flex-end',
            height: '100%',
          }}
        >
          <img src={girlPayment} alt={t('paymentIllustration')} style={{ width: '100%', maxWidth: '300px' }} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentDetailsForm;
